import { Row } from '@tanstack/react-table';

export function dateTimeSortFn<TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string): number {
    const dateStrA = rowA.getValue<string>(columnId);
    const dateStrB = rowB.getValue<string>(columnId);

    const dateA = new Date(dateStrA);
    const dateB = new Date(dateStrB);

    if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
        return 0;
    }

    if (isNaN(dateA.getTime())) {
        return 1;
    }

    if (isNaN(dateB.getTime())) {
        return -1;
    }

    return dateA.getTime() - dateB.getTime();
}

export function dateEqualsFilterFn<TData>(row: Row<TData>, columnId: string, filterValue: string[]) {
    const rowValueStr = row.getValue<string>(columnId);

    if (!rowValueStr || rowValueStr === '-') {
        return false;
    }

    const rowDate = new Date(rowValueStr);

    if (isNaN(rowDate.getTime())) {
        return false;
    }

    const rowYear = rowDate.getFullYear();
    const rowMonth = rowDate.getMonth();
    const rowDay = rowDate.getDate();

    return filterValue.some((filterDateStr) => {
        const filterDate = new Date(filterDateStr);

        if (isNaN(filterDate.getTime())) {
            return false;
        }

        return (
            rowYear === filterDate.getFullYear() &&
            rowMonth === filterDate.getMonth() &&
            rowDay === filterDate.getDate()
        );
    });
}

export function betweenNumberRangeFilterFn<TData>(row: Row<TData>, columnId: string, filterValue: [number, number]) {
    const rowValue = row.getValue<number>(columnId);

    const [min, max] = filterValue;

    return rowValue >= min && rowValue <= max;
}
