import { FormValues } from 'components/inspections/AnomalyMap/ModuleDetailsPanel/UploadFileModal';
import { Config } from 'config';
import { fetchData } from 'lib/hooks';

export const getAnomalyFileUploadUrl = async (
    formData: FormValues,
    siteId: string | undefined,
    anomalyUUID: string,
    superReportTaskUUID: string | undefined,
) => {
    const isImageFileType = formData.file.type.startsWith('image/');
    const categoryName = isImageFileType ? 'Anomaly Image' : 'Schematic';
    const contentType = formData.file.type;

    const { data, error } = await fetchData({
        url: `${Config.get('REEDLING_BLOB_MANAGEMENT_URL')}/blobs/upload`,
        method: 'POST',
        body: {
            is_internal: false,
            caption: formData.caption,
            references: {
                anomaly_uuid: anomalyUUID,
                site_uuid: siteId,
                category_name: categoryName,
                super_report_task_uuid: superReportTaskUUID,
            },
            name: formData.file.name,
            expiration_minutes: 15, // optional
            resumable: false,
            headers: {
                'content-type': contentType,
            },
            in_all_workspaces: false,
        },
        externalLink: false,
    });

    return { data, error };
};
