import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAtom, useSetAtom } from 'jotai';
import { isEmpty, uniq } from 'lodash';
import { useNavigationType, useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';
import {
    ANOMALY_ATTRIBUTES,
    NO_IR_SIGNAL,
    NO_RGB_SIGNAL,
    PivotViewOptions,
    REMEDIATION_LEVEL_1,
    REMEDIATION_LEVEL_2,
    REMEDIATION_LEVEL_3,
} from 'lib/constants';
import { buildDefaultPivotTableSettings, buildValueFilter, negateValueFilter } from 'lib/helpers';
import { formatISODate } from 'lib/helpers/dates';
import createCustomHook from 'lib/helpers/hookFactory';
import {
    translatedTabularTableFiltersAtom,
    useClearTabularTableFilter,
} from 'state/inspections/anomaliesTabularTableFilters';
import { flatColumnFiltersAtom } from 'state/inspections/pivotFilters';

import { useAnomalyData } from './useAnomalyData';

const ValueProvider = () => {
    const { rawData } = useAnomalyData();
    const siteData = useRouteLoaderData('site-details') as SiteLayoutLoaderData;
    const { summary } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const [data, setData] = useState([]);
    const [pivotDownloadData, setPivotDownloadData] = useState<any>(null);

    const clearTabularTableFilters = useClearTabularTableFilter();
    const applyTabularTableFilters = useSetAtom(translatedTabularTableFiltersAtom);
    const navigationType = useNavigationType();

    const anomalyNames = useMemo(
        () => uniq(data?.map((anomaly: any) => anomaly[ANOMALY_ATTRIBUTES.IR_SIGNAL])),
        [data],
    );
    const subsystems = useMemo(() => uniq(data?.map((anomaly: any) => anomaly[ANOMALY_ATTRIBUTES.SUBSYSTEM])), [data]);
    const remediationCategories = useMemo(
        () => uniq(data?.map((anomaly: any) => anomaly[ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY])),
        [data],
    );

    const DEFAULT_PIVOT_TABLE_SETTINGS = useMemo(
        () => buildDefaultPivotTableSettings(summary?.activeModules, siteData?.capacity, setPivotDownloadData),
        [summary, siteData],
    );

    const [view, setView] = useState<any>();
    const [flatColumnFilters, setFlatColumnFilters] = useAtom(flatColumnFiltersAtom);
    const [settings, setSettings] = useState<any>();
    const [aggregator, setAggregator] = useState(DEFAULT_PIVOT_TABLE_SETTINGS.aggregatorName);
    const [renderer, setRenderer] = useState(DEFAULT_PIVOT_TABLE_SETTINGS.rendererName);
    const [rowOrder, setRowOrder] = useState(DEFAULT_PIVOT_TABLE_SETTINGS.rowOrder);
    const [colOrder, setColOrder] = useState(DEFAULT_PIVOT_TABLE_SETTINGS.colOrder);
    const [anomalyNameFilter, setAnomalyNameFilter] = useState(null);
    const [subsystemFilter, setSubsystemFilter] = useState(null);
    const [remediationCategoryFilter, setRemediationCategoryFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    /**
     * Going to /pivot should clear tabular table filter atoms and set them
     * back to their original value.
     * It's a REPLACE navigation type since that is how tabular navigation
     * is set up.
     * */
    useEffect(() => {
        if (navigationType === 'REPLACE') {
            clearTabularTableFilters();
            applyTabularTableFilters();
        }
    }, [clearTabularTableFilters, navigationType, applyTabularTableFilters]);

    useEffect(() => {
        if (rawData) {
            setLoading(true);
            const pivotTableData = rawData?.map((anomaly: any) => {
                const transformedAnomaly = { ...anomaly };
                const irSignal = anomaly && anomaly[ANOMALY_ATTRIBUTES.IR_SIGNAL];
                const rgbSignal = anomaly && anomaly[ANOMALY_ATTRIBUTES.RGB_SIGNAL];
                const statusUpdatedDate = anomaly && anomaly[ANOMALY_ATTRIBUTES.STATUS_UPDATED_DATE];

                if (statusUpdatedDate && statusUpdatedDate !== '-') {
                    transformedAnomaly[ANOMALY_ATTRIBUTES.STATUS_UPDATED_DATE] = formatISODate(statusUpdatedDate);
                }

                if (irSignal === '-') {
                    transformedAnomaly[ANOMALY_ATTRIBUTES.IR_SIGNAL] = NO_IR_SIGNAL;
                }

                if (rgbSignal === '-') {
                    transformedAnomaly[ANOMALY_ATTRIBUTES.RGB_SIGNAL] = NO_RGB_SIGNAL;
                }

                return transformedAnomaly;
            });

            setData(pivotTableData);
            setLoading(false);
        }
    }, [rawData]);

    useEffect(() => {
        data?.length && setFlatColumnFilters(negateValueFilter(data, settings?.valueFilter));
    }, [data, settings?.valueFilter]);

    useEffect(() => {
        setSettings((prevSettings: any) => ({
            ...prevSettings,
            rowOrder,
        }));
    }, [rowOrder]);

    useEffect(() => {
        setSettings((prevSettings: any) => ({
            ...prevSettings,
            colOrder,
        }));
    }, [colOrder]);

    useEffect(() => {
        setSettings((prevSettings: any) => ({
            ...prevSettings,
            aggregatorName: aggregator,
        }));
    }, [aggregator]);

    useEffect(() => {
        setSettings((prevSettings: any) => ({
            ...prevSettings,
            rendererName: renderer,
        }));
    }, [renderer]);

    useEffect(() => {
        setSettings({
            ...DEFAULT_PIVOT_TABLE_SETTINGS,
            valueFilter: {
                [ANOMALY_ATTRIBUTES.IR_SIGNAL]: buildValueFilter(anomalyNames, anomalyNameFilter),
            },
        });
        // TODO: DEFAULT_PIVOT_TABLE_SETTINGS can't add to dependency array because it breaks functionality
    }, [anomalyNameFilter, anomalyNames]);

    useEffect(() => {
        setSettings({
            ...DEFAULT_PIVOT_TABLE_SETTINGS,
            valueFilter: {
                [ANOMALY_ATTRIBUTES.SUBSYSTEM]: buildValueFilter(subsystems, subsystemFilter),
            },
        });
        // TODO: DEFAULT_PIVOT_TABLE_SETTINGS can't add to dependency array because it breaks functionality
    }, [subsystemFilter, subsystems]);

    useEffect(() => {
        setSettings({
            ...DEFAULT_PIVOT_TABLE_SETTINGS,
            valueFilter: {
                [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY]: buildValueFilter(
                    remediationCategories,
                    remediationCategoryFilter,
                ),
            },
        });
        // TODO: DEFAULT_PIVOT_TABLE_SETTINGS can't add to dependency array because it breaks functionality
    }, [remediationCategories, remediationCategoryFilter]);

    const viewSettings: any = useMemo(
        () => ({
            [PivotViewOptions.ANOMALY_SUMMARY]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rows: [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY],
                cols: [],
                valueFilter: {},
            },
            [PivotViewOptions.REMEDIATION_RECOMMENDED]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [
                    ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY,
                    ANOMALY_ATTRIBUTES.SUBSYSTEM,
                    ANOMALY_ATTRIBUTES.IR_SIGNAL,
                ],
                cols: [ANOMALY_ATTRIBUTES.RGB_SIGNAL],
                valueFilter: {
                    [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY]: buildValueFilter(
                        remediationCategories,
                        REMEDIATION_LEVEL_1,
                    ),
                },
            },
            [PivotViewOptions.MONITOR_AND_REMEDIATE]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [
                    ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY,
                    ANOMALY_ATTRIBUTES.SUBSYSTEM,
                    ANOMALY_ATTRIBUTES.IR_SIGNAL,
                ],
                cols: [ANOMALY_ATTRIBUTES.RGB_SIGNAL],
                valueFilter: {
                    [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY]: buildValueFilter(
                        remediationCategories,
                        REMEDIATION_LEVEL_2,
                    ),
                },
            },
            [PivotViewOptions.LONG_TERM_MONITORING]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [
                    ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY,
                    ANOMALY_ATTRIBUTES.SUBSYSTEM,
                    ANOMALY_ATTRIBUTES.IR_SIGNAL,
                ],
                cols: [ANOMALY_ATTRIBUTES.RGB_SIGNAL],
                valueFilter: {
                    [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY]: buildValueFilter(
                        remediationCategories,
                        REMEDIATION_LEVEL_3,
                    ),
                },
            },
            [PivotViewOptions.REMEDIATION_BY_SUBSYSTEM]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [ANOMALY_ATTRIBUTES.SUBSYSTEM],
                cols: [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY],
                valueFilter: {},
            },
            [PivotViewOptions.DIFFERENTIAL_SUMMARY]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [ANOMALY_ATTRIBUTES.STATUS],
                cols: [],
                valueFilter: {},
            },
            [PivotViewOptions.DIFFERENTIAL_REMEDIATION_CATEGORIES]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY],
                cols: [ANOMALY_ATTRIBUTES.STATUS],
                valueFilter: {},
            },
            [PivotViewOptions.DIFFERENTIAL_SUBSYSTEM]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [ANOMALY_ATTRIBUTES.SUBSYSTEM],
                cols: [ANOMALY_ATTRIBUTES.STATUS],
                valueFilter: {},
            },
            [PivotViewOptions.OLDER_INSPECTION]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [
                    ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY,
                    ANOMALY_ATTRIBUTES.IR_SIGNAL,
                    ANOMALY_ATTRIBUTES.RGB_SIGNAL,
                ],
                cols: [ANOMALY_ATTRIBUTES.STATUS],
                valueFilter: {
                    Status: {
                        New: true,
                    },
                },
            },
            [PivotViewOptions.NEWER_INSPECTION]: {
                ...DEFAULT_PIVOT_TABLE_SETTINGS,
                aggregatorName: aggregator,
                rendererName: renderer,
                rows: [
                    ANOMALY_ATTRIBUTES.REMEDIATION_CATEGORY,
                    ANOMALY_ATTRIBUTES.IR_SIGNAL,
                    ANOMALY_ATTRIBUTES.RGB_SIGNAL,
                ],
                cols: [ANOMALY_ATTRIBUTES.STATUS],
                valueFilter: {
                    Status: {
                        Absent: true,
                    },
                },
            },
        }),
        [DEFAULT_PIVOT_TABLE_SETTINGS, aggregator, renderer, remediationCategories],
    );

    useEffect(() => {
        switch (view) {
            case PivotViewOptions.ANOMALY_SUMMARY:
            case PivotViewOptions.REMEDIATION_RECOMMENDED:
            case PivotViewOptions.MONITOR_AND_REMEDIATE:
            case PivotViewOptions.LONG_TERM_MONITORING:
            case PivotViewOptions.REMEDIATION_BY_SUBSYSTEM:
            case PivotViewOptions.DIFFERENTIAL_SUMMARY:
            case PivotViewOptions.DIFFERENTIAL_REMEDIATION_CATEGORIES:
            case PivotViewOptions.DIFFERENTIAL_SUBSYSTEM:
            case PivotViewOptions.OLDER_INSPECTION:
            case PivotViewOptions.NEWER_INSPECTION:
                setSettings(viewSettings[view]);
                break;
            case PivotViewOptions.CUSTOM:
                break;

            default:
                break;
        }
    }, [view, viewSettings]);

    const hasFilters = useMemo(
        () =>
            settings?.valueFilter &&
            Object.keys(settings.valueFilter).some((filterKey: string) => !isEmpty(settings.valueFilter[filterKey])),
        [settings],
    );

    const clearFilters = useCallback(() => {
        setAnomalyNameFilter(null);
        setSubsystemFilter(null);
        setRemediationCategoryFilter(null);
        setSettings({
            ...settings,
            valueFilter: {},
        });
        setFlatColumnFilters(null);
    }, [settings]);

    const value = useMemo(
        () => ({
            data,
            loading,
            settings,
            view,
            viewSettings,
            aggregator,
            renderer,
            hasFilters,
            flatColumnFilters,
            rowOrder,
            colOrder,
            pivotDownloadData,
            remediationCategories,
            setPivotDownloadData,
            setRowOrder,
            setColOrder,
            clearFilters,
            setView,
            setSettings,
            setAggregator,
            setRenderer,
            setAnomalyNameFilter,
            setSubsystemFilter,
            setRemediationCategoryFilter,
        }),
        [
            data,
            loading,
            settings,
            view,
            viewSettings,
            aggregator,
            renderer,
            hasFilters,
            flatColumnFilters,
            rowOrder,
            colOrder,
            pivotDownloadData,
            remediationCategories,
            setPivotDownloadData,
            setRowOrder,
            setColOrder,
            clearFilters,
            setView,
            setSettings,
            setAggregator,
            setRenderer,
            setAnomalyNameFilter,
            setSubsystemFilter,
            setRemediationCategoryFilter,
        ],
    );

    return value;
};

const { Provider: PivotTableProvider, useCustomHook: usePivotTable } = createCustomHook<any>({
    name: 'usePivotTable',
    callback: ValueProvider,
});

export { PivotTableProvider, usePivotTable };
