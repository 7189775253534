import { useState } from 'react';

export interface Metadata {
    url: string; // GCS URL
    [key: string]: string; // x-goog-meta headers
}

interface UploadState {
    progress: number;
    isUploading: boolean;
    error: string | null;
    isCompleted: boolean;
}

export const useGcsUpload = () => {
    const [uploadState, setUploadState] = useState<UploadState>({
        progress: 0,
        isUploading: false,
        error: null,
        isCompleted: false,
    });

    const uploadToGCS = (file: File, metadata: Metadata): Promise<void> =>
        new Promise((resolve, reject) => {
            if (!file || !metadata || !metadata.url) {
                const fileDataErrorMsg = 'File and metadata with URL are required.';

                setUploadState((prev) => ({
                    ...prev,
                    error: fileDataErrorMsg,
                }));

                reject(new Error(fileDataErrorMsg));
            }

            const xhr = new XMLHttpRequest();

            xhr.open('PUT', metadata.url);

            // Set headers
            xhr.setRequestHeader('Content-Type', file.type);
            Object.keys(metadata).forEach((key) => {
                if (key !== 'url') {
                    xhr.setRequestHeader(key, metadata[key]);
                }
            });

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentCompleted = Math.round((event.loaded / event.total) * 100);

                    setUploadState((prev) => ({
                        ...prev,
                        progress: percentCompleted,
                    }));
                }
            };

            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    setUploadState({
                        progress: 100,
                        isUploading: false,
                        error: null,
                        isCompleted: true,
                    });
                    resolve();
                } else {
                    const errorMsg = `File upload failed.`;

                    setUploadState({ progress: 0, isUploading: false, error: errorMsg, isCompleted: false });
                    reject(new Error(errorMsg));
                }
            };

            xhr.onerror = () => {
                const errorMsg = `Network error occurred during upload of file: ${file.name}`;

                setUploadState({ progress: 0, isUploading: false, error: errorMsg, isCompleted: false });
                reject(new Error(errorMsg));
            };

            setUploadState({ progress: 0, isUploading: true, error: null, isCompleted: false });
            xhr.send(file);
        });

    return { uploadToGCS, uploadState };
};
