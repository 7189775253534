type GetImageFileNameParams = {
    url: string;
    response: Response;
};

/**
 * Generates a complete image file name from a given URL and response object.
 *
 * @param {string} params.url - The URL of the image.
 * @param {Response} params.response - The response object containing headers.
 * @returns {string} The complete image file name with extension.
 *
 * @example
 * // Example usage:
 * const url = 'https://example.com/path/to/image.png?query=123';
 * const response = await fetch(url);
 * const fileName = getImageFileName({ url, response });
 * console.log(fileName); // Outputs: 'image.png'
 *
 * @remarks
 * This function extracts the file name from the URL and ensures it has a valid
 * file extension. If the URL does not contain an extension, it derives the
 * extension from the response's Content-Type header. If the Content-Type is
 * not available, it defaults to 'jpg'.
 */
export const getImageFileName = ({ url, response }: GetImageFileNameParams): string => {
    const urlObj = new URL(url);

    let fileName = urlObj.pathname.split('/').pop() || 'image';

    // Remove query parameters from the filename if present
    if (urlObj.search) {
        fileName = fileName.split('?')[0];
    }

    let fileExtension = '';

    if (fileName.includes('.')) {
        // Derive the file extension from the filename
        const fileNameParts = fileName.split('.');

        fileExtension = fileNameParts.pop() || '';
        fileName = fileNameParts.join('.');
    } else {
        // If no extension is in the filename, derive from response headers
        const contentType = response.headers.get('Content-Type') || '';
        const mimeTypeParts = contentType.split('/');

        fileExtension = mimeTypeParts[1] || 'jpg';

        // Handle special cases
        if (fileExtension === 'jpeg') {
            fileExtension = 'jpg';
        }
    }

    if (!fileExtension) {
        fileExtension = 'jpg';
    }

    return `${fileName}.${fileExtension}`;
};
