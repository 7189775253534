import { Container } from '@mantine/core';

// interface UploadedFilesListContainerProps {
//     files: {
//         name: string;
//     }[]
// }

export const UploadedFilesListContainer = () => (
    <Container size="md" p="md" className="uploaded-files-list-container">
        Placeholder
    </Container>
);
