import { useMemo } from 'react';

import { MultiSelect, RangeSlider } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { SelectItem, Stack } from '@mantine/core';

import { withComponentControlledByAtom } from 'components/hoc/withControlledComponentByAtom';
import { useAnomalyData } from 'lib/hooks';
import { tabularTableFilterAtoms } from 'state/inspections/anomaliesTabularTableFilters';

import { generateFilterOptions } from './filterUtils';
import { PriorityCheckboxGroup } from '../inputs/PriorityCheckboxGroup';

export const TabularTableFilters = () => {
    const { rawData } = useAnomalyData();

    const {
        anomalyStatusData,
        statusUpdatedDateData,
        priorityOptions,
        stackIdData,
        irSignalData,
        rgbSignalData,
        subsystemData,
        combinerIdData,
        inverterIdData,
        hasSliderData,
    } = useMemo(() => generateFilterOptions(rawData), [rawData]);

    return (
        <Stack>
            <TwoColumnsWrapper>
                <ControlledStackId data={stackIdData} />
                <ControlledIrSignal data={irSignalData} />
                <ControlledRgbSignal data={rgbSignalData} />
                <ControlledSubsystem data={subsystemData} />
            </TwoColumnsWrapper>
            <TwoColumnsWrapper>
                <ControlledPriority data={priorityOptions} />
            </TwoColumnsWrapper>
            <TwoColumnsWrapper>
                <ControlledAnomalyStatus data={anomalyStatusData} />
                <ControlledStatusUpdatedDate data={statusUpdatedDateData} />
            </TwoColumnsWrapper>
            <TwoColumnsWrapper>
                <ControlledEnergyLossWeight disabled={!hasSliderData['Energy Loss Weight']} />
                <ControlledPowerLoss disabled={!hasSliderData['Power Loss (kW)']} />
                <ControlledBaseTemperature disabled={!hasSliderData['Base Temperature']} />
                <ControlledAnomalyTemperature disabled={!hasSliderData['Anomaly Temperature']} />
                <ControlledTemperatureDelta disabled={!hasSliderData['Temperature Delta']} />
            </TwoColumnsWrapper>
            <TwoColumnsWrapper>
                <ControlledCombinerId data={combinerIdData} />
                <ControlledInverterId data={inverterIdData} />
            </TwoColumnsWrapper>
        </Stack>
    );
};

type ControlledMultiSelectProps = { value: string[]; onChange: (value: string[]) => void; data: SelectItem[] };
type ControlledRangeSlideProps = {
    value: [number, number];
    onChange: (value: [number, number]) => void;
    disabled: boolean;
};
type ControlledCheckboxGroupProps = {
    value: string[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    data: { label: string; value: string }[];
};

const StackId = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Stack ID" placeholder="Select Stack ID" {...props} />
);

const AnomalyStatus = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Anomaly Status" placeholder="Select Anomaly Status" {...props} />
);

const StatusUpdatedDate = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Status Updated Date" placeholder="Select Date" {...props} />
);

const IrSignal = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="IR Signal" placeholder="Select IR Signal" {...props} />
);

const RgbSignal = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="RGB Signal" placeholder="Select RGB Signal" {...props} />
);

const Subsystem = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Subsystem" placeholder="Select Subsystem" {...props} />
);

const CombinerId = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Combiner ID" placeholder="Select Combiner ID" {...props} />
);

const InverterId = (props: ControlledMultiSelectProps) => (
    <MultiSelect searchable clearable w="100%" label="Inverter ID" placeholder="Select Inverter ID" {...props} />
);

const Priority = (props: ControlledCheckboxGroupProps) => (
    <PriorityCheckboxGroup label="Priority" options={props.data} {...props} />
);

const EnergyLossWeight = (props: ControlledRangeSlideProps) => (
    <RangeSlider label="Energy Loss Weight" inputWidth="3.6rem" min={0.0} max={1.0} step={0.1} {...props} />
);

const PowerLoss = (props: ControlledRangeSlideProps) => (
    <RangeSlider label="Power Loss (kW)" inputWidth="3.6rem" min={0.0} max={100.0} step={0.1} {...props} />
);

const BaseTemperature = (props: ControlledRangeSlideProps) => (
    <RangeSlider label="Base Temperature" inputWidth="3.6rem" min={0.0} max={100.0} step={1} {...props} />
);

const AnomalyTemperature = (props: ControlledRangeSlideProps) => (
    <RangeSlider label="Anomaly Temperature" inputWidth="3.6rem" min={0.0} max={100.0} step={1} {...props} />
);

const TemperatureDelta = (props: ControlledRangeSlideProps) => (
    <RangeSlider label="Temperature Delta" inputWidth="3.6rem" min={0.0} max={100.0} step={0.1} {...props} />
);

export const ControlledStackId = withComponentControlledByAtom(tabularTableFilterAtoms['Stack ID'].atom, StackId);
export const ControlledAnomalyStatus = withComponentControlledByAtom(
    tabularTableFilterAtoms['Anomaly Status'].atom,
    AnomalyStatus,
);
export const ControlledStatusUpdatedDate = withComponentControlledByAtom(
    tabularTableFilterAtoms['Status Updated Date'].atom,
    StatusUpdatedDate,
);
export const ControlledIrSignal = withComponentControlledByAtom(tabularTableFilterAtoms['IR Signal'].atom, IrSignal);
export const ControlledRgbSignal = withComponentControlledByAtom(tabularTableFilterAtoms['RGB Signal'].atom, RgbSignal);
export const ControlledSubsystem = withComponentControlledByAtom(tabularTableFilterAtoms.Subsystem.atom, Subsystem);
export const ControlledCombinerId = withComponentControlledByAtom(
    tabularTableFilterAtoms['Combiner ID'].atom,
    CombinerId,
);
export const ControlledInverterId = withComponentControlledByAtom(
    tabularTableFilterAtoms['Inverter ID'].atom,
    InverterId,
);
export const ControlledPriority = withComponentControlledByAtom(tabularTableFilterAtoms.Priority.atom, Priority);
export const ControlledEnergyLossWeight = withComponentControlledByAtom(
    tabularTableFilterAtoms['Energy Loss Weight'].atom,
    EnergyLossWeight,
);
export const ControlledPowerLoss = withComponentControlledByAtom(
    tabularTableFilterAtoms['Power Loss (kW)'].atom,
    PowerLoss,
);
export const ControlledBaseTemperature = withComponentControlledByAtom(
    tabularTableFilterAtoms['Base Temperature'].atom,
    BaseTemperature,
);
export const ControlledAnomalyTemperature = withComponentControlledByAtom(
    tabularTableFilterAtoms['Anomaly Temperature'].atom,
    AnomalyTemperature,
);
export const ControlledTemperatureDelta = withComponentControlledByAtom(
    tabularTableFilterAtoms['Temperature Delta'].atom,
    TemperatureDelta,
);

const TwoColumnsWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px 24px',
});
