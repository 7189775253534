import { useCallback } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import fileDownload from 'js-file-download';

import { getImageFileName } from 'lib/helpers/getImageFileName';

const useDownloadImage = () => {
    const download = useCallback(async (url: string) => {
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const blob = await response.blob();
            const fileName = getImageFileName({ url, response });

            fileDownload(blob, fileName);
        } catch (error) {
            notifications.error({
                title: 'Download Failed',
                message: 'Failed to download image',
                withCloseButton: true,
                withIcon: true,
            });
        }
    }, []);

    return download;
};

export default useDownloadImage;
