import InfoModal from 'components/inspections/shared/InfoModal';

import DiagnosticReportInfoModal from './modals/DiagnosticReportInfoModal';
import FullMappingScopeInfoModal from './modals/FullMappingScopeInfoModal';
import ModuleAdvancedInfoModal from './modals/ModuleAdvancedInfoModal';
import ModuleBasicInfoModal from './modals/ModuleBasicInfoModal';
import StringLevelInfoModal from './modals/StringLevelInfoModal';
import { REPORT_TYPES } from '../../lib/constants/inspections';

const ReportTypeModalMap = {
    [REPORT_TYPES.MODULE_ADVANCED]: <ModuleAdvancedInfoModal />,
    [REPORT_TYPES.MODULE_BASIC]: <ModuleBasicInfoModal />,
    [REPORT_TYPES.DIAGNOSTIC_REPORT]: <DiagnosticReportInfoModal />,
    [REPORT_TYPES.FSX]: <FullMappingScopeInfoModal />,
    [REPORT_TYPES.STRING_LEVEL]: <StringLevelInfoModal />,
    // [REPORT_TYPES.GLASS_CRACK]: <GlassCrackInfoModal />, // TODO - add GlassCrackInfoModal
};

export const InspectionInfoModal = ({
    reportType,
    opened,
    onClose,
}: {
    reportType: string;
    opened: boolean;
    onClose: () => void;
}) => (
    <InfoModal opened={opened} onClose={onClose}>
        {ReportTypeModalMap[reportType]}
    </InfoModal>
);
