interface UploadCardProps {
    progress: number;
    isCompleted: boolean;
    error: string | null;
}

// temporary Upload Card for testing purpose
const UploadCard: React.FC<UploadCardProps> = ({ progress, isCompleted, error }) => (
    <div className="upload-card">
        {error ? (
            <p style={{ color: 'red' }}>Error: {error}</p>
        ) : isCompleted ? (
            <p>Upload Complete!</p>
        ) : (
            <p>Uploading... {progress}%</p>
        )}
    </div>
);

export default UploadCard;
