import { Loader } from '@dronebase/shared-ui-core';
import { Box, Group, Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { AnomalyEntry, InspectionLoaderData } from 'components/inspections/types';
import useGlassCrackImage from 'lib/hooks/useGlassCrackImage';

import AnomalyThumbnail from './AnomalyThumbnail';
import LegacyAnomalyImages from './LegacyAnomalyImages';

const AnomalyImages = ({ anomaly }: { anomaly: AnomalyEntry }) => {
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const { imageData, fetching: fetchingGlassCrackImage } = useGlassCrackImage(anomaly, reportType);

    const { legacyAnomaly } = anomaly.aoi;

    const hasAnomalyImages =
        reportType === 'Glass Crack Inspection' ||
        legacyAnomaly?.irSampleImages?.length > 0 ||
        legacyAnomaly?.vizSampleImages?.length > 0 ||
        !legacyAnomaly?.irSampleImages?.every((image) => !image?.url) ||
        !legacyAnomaly?.vizSampleImages?.every((image) => !image?.url);

    return (
        <Group>
            {reportType === 'Glass Crack Inspection' && (
                <>
                    {fetchingGlassCrackImage ? (
                        <Box p="lg">
                            <Loader />
                        </Box>
                    ) : (
                        <AnomalyThumbnail src={imageData?.url} alt="Glass Crack" isSampleImage={false} />
                    )}
                </>
            )}
            <LegacyAnomalyImages anomaly={anomaly} showSampleImages />
            {!hasAnomalyImages && (
                <Text variant="body2light" c="gray.0">
                    -
                </Text>
            )}
        </Group>
    );
};

export default AnomalyImages;
