import { useMemo } from 'react';

import { Checkbox } from '@mantine/core';

type PriorityCheckboxGroupFilterProps = {
    label: string;
    options: { label: string; value: string }[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    value: string[];
};

const staticPriorityOptions = [
    {
        label: '1 - Remediation Recommended',
        value: '1 - Remediation Recommended',
    },
    {
        label: '2 - Monitor and Remediate',
        value: '2 - Monitor and Remediate',
    },
    {
        label: '3 - Long Term Monitoring',
        value: '3 - Long Term Monitoring',
    },
];

export const PriorityCheckboxGroup = ({ label, onChange, options, value }: PriorityCheckboxGroupFilterProps) => {
    const createOnChange = (changedCheckboxValue: string) => () => {
        onChange((previousSelectionArray) =>
            previousSelectionArray.includes(changedCheckboxValue)
                ? previousSelectionArray.filter((value) => value !== changedCheckboxValue)
                : [...previousSelectionArray, changedCheckboxValue],
        );
    };

    const optionValues = useMemo(() => options.map((option) => option.value), [options]);

    return (
        <Checkbox.Group
            styles={
                options?.length
                    ? undefined
                    : {
                          label: { color: 'var(--color-typography-disabled) !important' },
                      }
            }
            value={value}
            label={label}
            onChange={onChange}
        >
            {staticPriorityOptions.map((option) => (
                <Checkbox
                    disabled={!options?.length || !optionValues.includes(option.value)}
                    key={option.label}
                    value={option.value}
                    label={option.label}
                    styles={{ label: { '&[data-disabled]': { color: 'var(--color-typography-disabled) !important' } } }}
                    py="xs"
                    onChange={() => createOnChange(option.value)}
                    checked={value.includes(option.value)}
                />
            ))}
        </Checkbox.Group>
    );
};
