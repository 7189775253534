import { AnomalyEntry } from 'components/inspections/types';
import { getImageUrlsFromLegacyAnomaly } from 'lib/helpers';

import AnomalyThumbnail from './AnomalyThumbnail';

const LegacyAnomalyImages = ({
    anomaly,
    showSampleImages = false,
}: {
    anomaly: AnomalyEntry;
    showSampleImages: boolean;
}) => {
    const { irUrl, vizUrl, isIrSample, isVizSample } = getImageUrlsFromLegacyAnomaly(
        anomaly.aoi.legacyAnomaly,
        showSampleImages,
    );

    return (
        <>
            {irUrl && <AnomalyThumbnail src={irUrl} alt="IR" isSampleImage={isIrSample} />}
            {vizUrl && <AnomalyThumbnail src={vizUrl} alt="RGB" isSampleImage={isVizSample} />}
        </>
    );
};

export default LegacyAnomalyImages;
