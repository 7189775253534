import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import {
    COMBINER_ASSET_TYPE_UUID,
    DISPLAY_MAP_LAYER_KEYS,
    INVERTER_ASSET_TYPE_UUID,
    MAP_LAYER_ASSET_TYPES,
    STACK_ASSET_TYPE_UUID,
} from 'lib/constants';
import { getAssetLabels } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { GET_ASSETS_BY_TYPE_AND_SITE } from 'lib/queries';

const ValueProvider = () => {
    const { inspectionId } = useParams();

    const [{ data: combinerAssetData }] = useQuery({
        query: GET_ASSETS_BY_TYPE_AND_SITE,
        variables: {
            inspectionId,
            assetRegionTypeUuids: [COMBINER_ASSET_TYPE_UUID],
        },
    });

    const [{ data: inverterAssetData, fetching: fetchingInverterAssetData }] = useQuery({
        query: GET_ASSETS_BY_TYPE_AND_SITE,
        variables: {
            inspectionId,
            assetRegionTypeUuids: [INVERTER_ASSET_TYPE_UUID],
        },
    });

    const [{ data: stackAssetData }] = useQuery({
        query: GET_ASSETS_BY_TYPE_AND_SITE,
        variables: {
            inspectionId,
            assetRegionTypeUuids: [STACK_ASSET_TYPE_UUID],
        },
    });

    const combinerLabels = getAssetLabels(combinerAssetData, MAP_LAYER_ASSET_TYPES.COMBINER);
    const inverterLabels = getAssetLabels(inverterAssetData, MAP_LAYER_ASSET_TYPES.INVERTER);
    const stackLabels = getAssetLabels(stackAssetData, MAP_LAYER_ASSET_TYPES.STACK);

    const defaultFilteredAssetLayers = useMemo(
        () => [
            DISPLAY_MAP_LAYER_KEYS.OUTLINE,
            inverterLabels?.labels.length > 0 ? DISPLAY_MAP_LAYER_KEYS.INVERTER : DISPLAY_MAP_LAYER_KEYS.STACK,
        ],
        [inverterLabels],
    );

    const disabledFilterKeys = useMemo(
        () => [
            ...(combinerLabels?.disabled ? ['combiner'] : []),
            ...(inverterLabels?.disabled ? ['inverter'] : []),
            ...(stackLabels?.disabled ? ['stack'] : []),
        ],
        [combinerLabels?.disabled, inverterLabels?.disabled, stackLabels?.disabled],
    );

    const value = useMemo(
        () => ({
            combinerLabels: combinerLabels?.labels,
            inverterLabels: inverterLabels?.labels,
            stackLabels: stackLabels?.labels,
            disabledFilterKeys,
            defaultFilteredAssetLayers,
            fetchingInverterAssetData,
        }),
        [
            combinerLabels?.labels,
            disabledFilterKeys,
            inverterLabels?.labels,
            stackLabels?.labels,
            defaultFilteredAssetLayers,
            fetchingInverterAssetData,
        ],
    );

    return value;
};

const { Provider: AssetLabelsProvider, useCustomHook: useAssetLabels } = createCustomHook<any>({
    name: 'useAnomalyMapData',
    callback: ValueProvider,
});

export { AssetLabelsProvider, useAssetLabels };
