import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { AnomalyEntry } from 'components/inspections/types';
import { GET_ANOMALY_IMAGES } from 'lib/queries';
import { BlobInstance } from 'lib/types/inspections/anomalies';

const useGlassCrackImage = (anomaly: AnomalyEntry, reportType: string) => {
    const { siteId } = useParams();

    const [{ data, fetching, error }, refetchGlassCrackImageData] = useQuery({
        variables: {
            siteUuid: siteId,
            anomalyUuid: anomaly.uuid,
        },
        pause: reportType !== 'Glass Crack Inspection' || !anomaly.uuid,
        query: GET_ANOMALY_IMAGES,
        requestPolicy: 'network-only',
    });

    const glassCrackImageData = useMemo(() => {
        if (data?.reedling_index_blob_instance_index.length) {
            const images = data.reedling_index_blob_instance_index.filter((blobInstance: BlobInstance) =>
                blobInstance.blob_index.type.startsWith('image/'),
            );

            if (images.length > 0) {
                // At the moment, for glass crack inspection, we only want to show the first image
                const image = images[0];

                return {
                    url: image.url,
                    fileName: image.blob_index.object_name.split('/').pop(),
                    type: image.blob_index.type,
                };
            }
        }

        return null;
    }, [data]);

    return { imageData: glassCrackImageData, fetching, error, refetchGlassCrackImageData };
};

export default useGlassCrackImage;
