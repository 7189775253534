import { ConfirmModal, notifications } from '@dronebase/shared-ui-core';
import { FileInput, Stack, TextInput } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Metadata } from 'lib/hooks/useGCSUpload';
import { getAnomalyFileUploadUrl } from 'lib/mutations/getAnomalyFileUploadUrl';
import { LightThemeProvider } from 'providers/LightThemeProvider';

interface UploadFileModalProps {
    handleUpload: (file: File, metadata: Metadata) => void;
    opened: boolean;
    onClose: () => void;
    siteId: string | undefined;
    anomalyUUID: string;
}
export interface FormValues {
    caption: string;
    file: File;
}

const UploadFileModal = ({ handleUpload, opened, onClose, siteId, anomalyUUID }: UploadFileModalProps) => {
    const { control, handleSubmit, reset } = useForm<FormValues>();
    const MAX_FILE_SIZE_MB = 20;
    const MAX_FILE_SIZE = 1024 * 1024 * MAX_FILE_SIZE_MB; // 20MB
    const { inspectionId } = useParams();

    const onSubmit = async (data: FormValues) => {
        const formData = new FormData();

        formData.append('caption', data.caption);
        formData.append('file', data.file);
        const { data: uploadAnomalyFileResponseData, error: uploadAnomalyFileResponseError } =
            await getAnomalyFileUploadUrl(data, siteId, anomalyUUID, inspectionId);

        if (uploadAnomalyFileResponseData) {
            const metadata = {
                url: uploadAnomalyFileResponseData.url,
                ...uploadAnomalyFileResponseData.headers,
            };

            handleUpload(data.file, metadata);
            reset();
            onClose();
        } else if (uploadAnomalyFileResponseError) {
            notifications.error({
                title: 'Error uploading file. Please try again later.',
                withCloseButton: true,
                withIcon: true,
            });
            onClose();
        }
    };

    return (
        <LightThemeProvider>
            <ConfirmModal
                confirmButtonProps={{ type: 'submit' }}
                confirmLabel="Upload"
                modalContentProps={{
                    cancelButtonProps: {
                        children: 'Cancel',
                    },
                }}
                onConfirm={handleSubmit(onSubmit)}
                buttonPosition="start"
                opened={opened}
                onClose={onClose}
                title="Upload File"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack>
                        <Controller
                            name="caption"
                            control={control}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                                <TextInput
                                    label="Caption"
                                    placeholder="Enter your caption"
                                    withAsterisk
                                    {...field}
                                    error={fieldState.error?.message}
                                />
                            )}
                            rules={{ required: 'Caption is required' }}
                        />
                        <Controller
                            name="file"
                            control={control}
                            render={({ field, fieldState }) => (
                                <FileInput
                                    clearable
                                    label={`Select File (Max. ${MAX_FILE_SIZE_MB}MB)`}
                                    withAsterisk
                                    {...field}
                                    error={fieldState.error?.message}
                                />
                            )}
                            rules={{
                                required: 'File is required',
                                validate: (file) =>
                                    file
                                        ? file.size <= MAX_FILE_SIZE ||
                                          `File size must not exceed ${MAX_FILE_SIZE_MB}MB`
                                        : 'File is required',
                            }}
                        />
                    </Stack>
                </form>
            </ConfirmModal>
        </LightThemeProvider>
    );
};

export default UploadFileModal;
