import { CSSProperties, useCallback, useEffect, useState } from 'react';

import { TableHeader } from '@dronebase/shared-ui-core';
import {
    SortingState,
    Header,
    Column,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnFiltersState,
} from '@tanstack/react-table';
import { useAtom, useSetAtom } from 'jotai';
import _ from 'lodash';
import { NavigationType } from 'react-router-dom';

import {
    defaultTabularColumns,
    TableAnomaly,
    defaultTabularTableColumnVisibilityState,
} from 'components/inspections/AnomalyData/tabularTableColumns';
import { SortedBy } from 'components/Sites/types';
import { HIDDEN_TABULAR_TABLE_ANOMALY_ATTRIBUTES, NO_RGB_SIGNAL, NO_IR_SIGNAL, SORT_DIRECTION } from 'lib/constants';
import { betweenNumberRangeFilterFn, dateEqualsFilterFn, dateTimeSortFn } from 'lib/filters/anomaly';
import { formatISODate } from 'lib/helpers/dates';
import {
    useResetTabularTableFilter,
    useClearTabularTableFilter,
    usePopulateTabularTableFiltersFromPivotTable,
    translatedTabularTableFiltersAtom,
} from 'state/inspections/anomaliesTabularTableFilters';
import { tabularTableColumnVisibilityAtom, tabularTableCSVDataAtom } from 'state/inspections/anomalyTabularTable';

import { useAnomalyData } from './useAnomalyData';
import { usePivotTable } from './usePivotTable';

const useTabularTable = (isScrollingHorizontally?: boolean, navigationType?: NavigationType) => {
    const [data, setData] = useState<TableAnomaly[]>([]);
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState<SortingState>([{ id: 'Stack ID', desc: true }]);
    const [translatedTabularTableFilters, applyTabularTableFilters] = useAtom(translatedTabularTableFiltersAtom);
    const [tabularFilters, setTabularFilters] = useState<ColumnFiltersState>(translatedTabularTableFilters);
    const [columnVisibilityState, setColumnVisibilityState] = useAtom(tabularTableColumnVisibilityAtom);
    const setTabularTableCSVData = useSetAtom(tabularTableCSVDataAtom);
    const cancelDrawerFilters = useResetTabularTableFilter();
    const clearDrawerFilters = useClearTabularTableFilter();
    const populateTabularTableFiltersFromPivotTable = usePopulateTabularTableFiltersFromPivotTable();

    const applyDrawerFilters = useCallback(() => {
        setTabularFilters(translatedTabularTableFilters);
        applyTabularTableFilters();
    }, [translatedTabularTableFilters, applyTabularTableFilters]);

    const { rawData } = useAnomalyData();
    const { flatColumnFilters: pivotFilters } = usePivotTable();

    // This useEffect is for when you go from Pivot to Tabular tab
    // (e.g. navigation type is PUSH). You want to preserve the Pivot filters in
    // Tabular tab. When you go to Tabular tab directly via link share or
    // refresh (e.g. navigation type is POP), Pivot filters are not in play.
    useEffect(() => {
        if (pivotFilters && pivotFilters.length > 0 && navigationType === 'PUSH') {
            populateTabularTableFiltersFromPivotTable();
            setTabularFilters(pivotFilters);
        }
    }, [pivotFilters, navigationType, populateTabularTableFiltersFromPivotTable]);

    const clearFilters = () => {
        clearDrawerFilters();
        setTabularFilters([]);
        table.resetColumnFilters();
    };

    const resetToDefaultColumnVisibilityState = useCallback(() => {
        setColumnVisibilityState(defaultTabularTableColumnVisibilityState);
    }, [setColumnVisibilityState]);

    const table = useReactTable<TableAnomaly>({
        data,
        columns: defaultTabularColumns,
        state: { sorting, columnFilters: tabularFilters, columnVisibility: columnVisibilityState },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        onColumnFiltersChange: applyTabularTableFilters,
        onColumnVisibilityChange: setColumnVisibilityState,
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            columnPinning: {
                left: ['Stack ID', 'Position ID'],
            },
            pagination: { pageSize: 25 },
        },
        sortingFns: { dateTimeSortFn },
        filterFns: {
            pivotTableFilterFn: (row, columnId, filterValue) => filterValue.includes(row.original[columnId]),
            betweenNumberRangeFilterFn,
            dateEqualsFilterFn,
        },
    });

    useEffect(() => {
        const tabularTableData = _.map(rawData, (anomaly) => {
            const powerLoss = anomaly['Power Loss (kW)'];
            const irSignal = anomaly['IR Signal'];
            const rgbSignal = anomaly['RGB Signal'];
            const statusUpdatedDate = anomaly['Status Updated Date'];

            const transformedAnomaly: TableAnomaly = {
                ...anomaly,
                'IR Signal': irSignal === '-' ? NO_IR_SIGNAL : irSignal,
                'RGB Signal': rgbSignal === '-' ? NO_RGB_SIGNAL : rgbSignal,
                'Power Loss (kW)': powerLoss ? (powerLoss / 1000).toFixed(2) : '-',
                'Status Updated Date':
                    statusUpdatedDate && statusUpdatedDate !== '-' ? formatISODate(statusUpdatedDate, true) : '-',
            };

            // Fill empty fields with '-'
            _.forOwn(transformedAnomaly, (value, key) => {
                if (_.isEmpty(value)) {
                    transformedAnomaly[key as keyof TableAnomaly] = '-';
                }
            });

            return _.omit(transformedAnomaly, HIDDEN_TABULAR_TABLE_ANOMALY_ATTRIBUTES);
        }) as TableAnomaly[];

        setData(tabularTableData);
        setLoading(false);
    }, [rawData, table]);

    const { rows: filteredRows } = table.getFilteredRowModel();
    const visibleColumns = table.getVisibleFlatColumns();

    useEffect(() => {
        const data = _.map(filteredRows, (filteredRow) =>
            _.reduce(
                visibleColumns,
                (csvRow, column) => {
                    csvRow[column.id] = filteredRow.getValue(column.id);

                    return csvRow;
                },
                {} as Record<string, string>,
            ),
        );

        setTabularTableCSVData(data);
    }, [setTabularTableCSVData, filteredRows, table, visibleColumns]);

    const getCommonPinningStyles = useCallback(
        (column: Column<TableAnomaly>): CSSProperties => {
            const isPinned = column.getIsPinned();
            const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');

            return {
                borderRight:
                    isLastLeftPinnedColumn && isScrollingHorizontally ? '1px solid var(--color-grey-100)' : 'none',
                left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
                position: isPinned ? 'sticky' : 'relative',
                backgroundColor: 'var(--color-white)',
                width: column.getSize(),
                zIndex: isPinned ? 1 : 0,
                opacity: 1,
            };
        },
        [isScrollingHorizontally],
    );

    const handleSort = useCallback((key: string, direction: SortedBy['direction']) => {
        setSorting([{ id: key, desc: direction.startsWith('desc') }]);
    }, []);

    const handleColumnHeaderClick = useCallback(
        (header: Header<TableAnomaly, unknown>) => {
            if (header.column.getCanSort()) {
                const sort = sorting[0];
                const isDesc = sort.id === header.id ? !sort.desc : sort.desc;

                handleSort(header.id, isDesc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
            }
        },
        [sorting, handleSort],
    );

    const renderHeader = useCallback(
        (header: Header<TableAnomaly, unknown>) => {
            const { column } = header;
            const sortDirection = sorting[0]?.id === column.id ? (sorting[0]?.desc ? 'desc' : 'asc') : undefined;
            const columnIsSortable = column.getCanSort();
            const activeColumn = sorting[0]?.id === column.id;
            const columnName = column.columnDef.header as string;

            return (
                <TableHeader
                    isActive={activeColumn}
                    isSortable={columnIsSortable}
                    title={columnName}
                    sortDirection={sortDirection}
                    onClick={() => handleColumnHeaderClick(header)}
                    key={header.id}
                    style={{ ...getCommonPinningStyles(column), padding: '0.125rem 0.75rem' }}
                >
                    {columnName}
                </TableHeader>
            );
        },
        [getCommonPinningStyles, handleColumnHeaderClick, sorting],
    );

    return {
        data,
        table,
        loading,
        renderHeader,
        getCommonPinningStyles,
        clearFilters,
        tabularFilters,
        setTabularFilters,
        resetToDefaultColumnVisibilityState,
        columnVisibilityState,
        applyFilters: applyDrawerFilters,
        cancelFilters: cancelDrawerFilters,
    };
};

export { useTabularTable };
