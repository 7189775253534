import { useState, useMemo } from 'react';

import { Loader, notifications } from '@dronebase/shared-ui-core';
import { Upload } from '@dronebase/shared-ui-icons';
import { Button, Center, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtomValue } from 'jotai';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import { AnomalyEntry, InspectionLoaderData } from 'components/inspections/types';
import { ROUTES } from 'lib/constants/routes';
import { formatDateWithLocalTime } from 'lib/helpers';
import { useAnomalyMapData, useAnomalyOverviewFilters, useDifferentialMode } from 'lib/hooks';
import { useAssetRegionAnomalies } from 'lib/hooks/useAssetRegionAnomalies';
import { useFeatureFlag } from 'lib/hooks/useFeatureFlag';
import { Metadata, useGcsUpload } from 'lib/hooks/useGCSUpload';
import { useNavigationBlocker } from 'lib/hooks/useNavigationBlocker';
import { changeAnomalyStatus } from 'lib/mutations/changeAnomalyStatus';
import { InspectionAnomaly } from 'lib/types/inspections/anomaliesMap';
import { anomalyTabDropdownInspectionIdAtom } from 'state/inspections/anomaliesMap';

import AnomalyImages from './AnomalyImages';
import AnomalyPriority from './AnomalyPriority';
import { AnomalyStatusSelection } from './AnomalyStatusSelection';
import DiscardChangesModal from './DiscardChangesModal';
import UploadCard from './GcsUploadComponent';
import LegacyAnomalyImages from './LegacyAnomalyImages';
import ModuleTabFooter from './ModuleTabFooter';
import { SingleDetail } from './SingleDetail';
import { TabContentContainer } from '../TabContainers.styled';
import { UploadedFilesListContainer } from './UploadedFilesListContainer';
import UploadFileModal from './UploadFileModal';

// TODO: SLR-1495 simplify this component
// eslint-disable-next-line complexity
const AnomalyTabContent = () => {
    const {
        active: differentialModeIsActive,
        previousInspectionSummary,
        differentialModeInspectionId,
    } = useDifferentialMode();
    const { anomalyStatuses } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const anomalyStatusOptions = useMemo(() => anomalyStatuses.map((status) => status.name), [anomalyStatuses]);
    const { superAnalysisTaskUUIDs } = useAnomalyOverviewFilters();
    const { activeMapAnomalies, setMapAnomalies } = useAnomalyMapData();
    const { siteId, inspectionId, assetRegionUUID, anomalyTabNumber } = useParams();
    const navigate = useNavigate();
    const anomalyTabDropdownInspectionId = useAtomValue(anomalyTabDropdownInspectionIdAtom);
    const isAnomalyFileUploadEnabled = useFeatureFlag('IS_ANOMALY_FILE_UPLOAD_ENABLED');

    const [opened, { open, close }] = useDisclosure(false);
    const { uploadToGCS, uploadState } = useGcsUpload();

    const handleUpload = (file: File, metadata: Metadata) => {
        uploadToGCS(file, metadata).catch((err) => {
            notifications.error({
                title: err.message,
                withCloseButton: true,
                withIcon: true,
            });
        });
    };

    const {
        anomalies: currentInspectionAnomalies,
        fetching: currentInspectionFetching,
        refetchAssetRegionAnomalies,
    } = useAssetRegionAnomalies({
        assetRegionUuid: assetRegionUUID,
        superAnalysisTaskUUIDs,
    });

    // Do not need to sort previous inspection anomalies because we're only going to show one
    const { anomalies: previousInspectionAnomalies, fetching: previousInspectionFetching } = useAssetRegionAnomalies({
        assetRegionUuid: assetRegionUUID,
        superAnalysisTaskUUIDs: previousInspectionSummary?.superAnalysisTasks[0]?.superAnalysisTaskUUID,
    });

    const [anomalyStatusSelection, setAnomalyStatusSelection] = useState({
        id: 0,
        name: '',
        hasChanged: false,
    });

    const currentAndPreviousAnomaliesDictionary = useMemo(
        () => ({
            [`${inspectionId}`]: currentInspectionAnomalies,
            [`${previousInspectionSummary?.inspectionID || 'no-previous-inspection'}`]:
                previousInspectionAnomalies || [],
        }),
        [
            inspectionId,
            previousInspectionAnomalies,
            previousInspectionSummary?.inspectionID,
            currentInspectionAnomalies,
        ],
    );

    const inspectionIdLookUp =
        differentialModeInspectionId === anomalyTabDropdownInspectionId || !anomalyTabDropdownInspectionId
            ? differentialModeInspectionId
            : anomalyTabDropdownInspectionId;

    const navigationBlocker = useNavigationBlocker(anomalyStatusSelection.hasChanged);

    if (currentInspectionFetching || previousInspectionFetching) {
        return (
            <Center h="100%">
                <Loader />
            </Center>
        );
    }

    if (!inspectionId || !assetRegionUUID || !anomalyTabNumber) {
        return null;
    }

    const anomalyToDisplay: AnomalyEntry = differentialModeIsActive
        ? currentAndPreviousAnomaliesDictionary[inspectionIdLookUp][0]
        : currentInspectionAnomalies[Number(anomalyTabNumber) - 1];

    if (!anomalyToDisplay) {
        return null;
    }

    const anomalyType = anomalyToDisplay.anomalyDefinition.globalDefinition.anomalyDefinitionName;
    const anomalyPriority = anomalyToDisplay.anomalyDefinition.priority.toString() as '1' | '2' | '3';
    const anomalySubsystem = anomalyToDisplay.anomalyDefinition.subsystem.subsystemName;
    const anomalyDescription = anomalyToDisplay.anomalyDefinition.description;
    const statusUpdatedDate = anomalyToDisplay.clientUpdatedAt
        ? formatDateWithLocalTime(anomalyToDisplay.clientUpdatedAt)
        : '-';
    const anomalyUUID = anomalyToDisplay.uuid;

    const handleSave = async () => {
        const newAnomalyStatusId =
            anomalyStatuses.find((status) => status.name === anomalyStatusSelection.name)?.id ?? 0;

        const successCallback = () => {
            const updatedAnomaly = activeMapAnomalies[assetRegionUUID];

            if (updatedAnomaly) {
                updatedAnomaly.species[Number(anomalyTabNumber) - 1].statusId = String(newAnomalyStatusId);

                setMapAnomalies({
                    ...activeMapAnomalies,
                    [assetRegionUUID]: updatedAnomaly,
                });
            }

            setAnomalyStatusSelection((prev) => ({ ...prev, hasChanged: false }));
            refetchAssetRegionAnomalies();
        };

        await changeAnomalyStatus(inspectionId, anomalyToDisplay.uuid, newAnomalyStatusId, successCallback);
    };

    const handleDiscard = () => {
        navigationBlocker.proceed();

        setAnomalyStatusSelection({
            id: anomalyStatuses.find((status) => status.name === anomalyToDisplay.anomalyStatus.name)?.id ?? 0,
            name: anomalyToDisplay.anomalyStatus.name ?? anomalyStatusOptions[0],
            hasChanged: false,
        });
    };

    const currentAnomalyStatus = anomalyStatusSelection.hasChanged
        ? anomalyStatusSelection.name
        : anomalyToDisplay.anomalyStatus.name;

    const handleAnomalyTabChange = (tab: string) => {
        navigate(
            `${ROUTES.sites.index}/${siteId}/${inspectionId}/${ROUTES.sites.inspection.map.index}/` +
                `${assetRegionUUID}/${ROUTES.sites.inspection.map.anomaly}/${Number(tab)}`,
        );
    };

    return (
        <>
            <DiscardChangesModal
                opened={navigationBlocker.isNavigationBlocked}
                onClose={navigationBlocker.halt}
                onDiscard={handleDiscard}
            />
            <TabContentContainer>
                {currentInspectionAnomalies.length > 1 && !differentialModeIsActive && (
                    <Tabs
                        variant="segmented"
                        value={anomalyTabNumber}
                        onTabChange={handleAnomalyTabChange}
                        h="max-content"
                    >
                        <Tabs.List>
                            {currentInspectionAnomalies.map((anomaly: InspectionAnomaly, index: number) => (
                                <Tabs.Tab fz="var(--body2-font-size)" key={anomaly.uuid} value={`${index + 1}`}>
                                    Anomaly {index + 1}
                                </Tabs.Tab>
                            ))}
                        </Tabs.List>
                    </Tabs>
                )}
                <SingleDetail label="Type" value={anomalyType} />
                <SingleDetail label="Priority">
                    <AnomalyPriority anomalyPriority={anomalyPriority} />
                </SingleDetail>
                <SingleDetail label="Subsystem" value={anomalySubsystem} />
                <SingleDetail label="Status">
                    <AnomalyStatusSelection
                        anomalyStatusOptions={anomalyStatusOptions}
                        currentAnomalyStatus={currentAnomalyStatus}
                        onStatusChange={(newStatus: string) => {
                            setAnomalyStatusSelection({
                                id: anomalyStatuses.find((status) => status.name === newStatus)?.id ?? 0,
                                name: newStatus,
                                hasChanged: anomalyToDisplay.anomalyStatus.name !== newStatus,
                            });
                        }}
                    />
                </SingleDetail>
                <SingleDetail label="Status Updated Date" value={statusUpdatedDate} />
                <SingleDetail label="Images">
                    <AnomalyImages anomaly={anomalyToDisplay} />
                </SingleDetail>
                <SingleDetail label="Description" value={anomalyDescription} />
                {isAnomalyFileUploadEnabled && (
                    <SingleDetail label="Uploaded Files">
                        <UploadedFilesListContainer />
                        <Button leftIcon={<Upload />} onClick={open}>
                            Upload
                        </Button>
                        <UploadFileModal
                            handleUpload={handleUpload}
                            opened={opened}
                            onClose={close}
                            siteId={siteId}
                            anomalyUUID={anomalyUUID}
                        />
                        {uploadState.isUploading || uploadState.isCompleted ? (
                            <UploadCard
                                progress={uploadState.progress}
                                isCompleted={uploadState.isCompleted}
                                error={uploadState.error}
                            />
                        ) : null}
                    </SingleDetail>
                )}
            </TabContentContainer>
            <ModuleTabFooter
                onCancel={handleDiscard}
                disabled={!anomalyStatusSelection.hasChanged}
                onSave={handleSave}
            />
        </>
    );
};

export default AnomalyTabContent;
